import React from 'react';
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import { withI18n } from '@lingui/react';
import { prefix } from '../i18n-config';

import Arrow from '../components/img/Arrow';

function Shop(props) {
  const { pageContext, data, location } = props;
  const lang = pageContext.lang;

  return (
    <div className="page page--shop page--light">
      <Helmet titleTemplate="%s - Interstellar Interactive">
        <meta name="og:type" content="website" />
        <meta name="og:site_name" content="Interstellar Interactive" />
        <title>Lab</title>
        <link
          rel="canonical"
          href={`https://lumensoftware.io${location.pathname}`}
        />
        <html lang={lang} />
        {/* <meta name="description" content={content.seo} /> */}
      </Helmet>
      <div className="page__content">
        <div className="container">
        </div>
      </div>
    </div>
  );
}

export default withI18n()(Shop);

